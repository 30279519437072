export const isRunningOnWeb = () => {
  return typeof window !== 'undefined'// && window.localStorage;
}

export const isMobile = () => {
  const userAgent: string = isRunningOnWeb() && 
    typeof window.navigator !== "undefined" ? navigator.userAgent : "";
  const _isMobile: boolean = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
  return _isMobile;
}