import * as React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from '../../functions/utils';

// --- Description Window Component | chapter = number - positionX = right-[] - positionY = top-[] - toboContent = text
const DescriptionCards = (props: {
    chapter: number,
    right: string, 
    top: string,
    toboContent: any,
}) => {

    const _isMobile: boolean = isMobile();

    const [isCardOpen, setIsCardOpen] = useState<boolean>(false);

    const chapters: any = props?.toboContent && props.toboContent?.Chapter && props.toboContent.Chapter; 
    const chapter: number = props?.chapter && props.chapter;
    const chapterTitle: string = chapters && chapters[chapter && chapter]?.chapterTitle && chapters[chapter]?.chapterTitle;
    const chapterDescription: string = chapters && chapters[chapter]?.chapterDescription && chapters[chapter].chapterDescription.data.chapterDescription;

    const [cardContentID, setCardContentID] = useState<string>('card-content' + chapter);
    
    useEffect(() => {
        
        const cardContent: HTMLElement | null = document.getElementById(cardContentID);

        if (!isCardOpen) {
            cardContent?.classList.remove('cardContentFadeIn');
        } else if (isCardOpen) {
            cardContent?.classList.add('cardContentFadeIn');
        }

    }, [isCardOpen])

    return (
        <div className='absolute z-40 flex justify-start' style={{top: `${props?.top}%`, right: `${props?.right}%`}}>
            <div className='flex flex-col group'>
                <div className='flex flex-row'>
                    <div id='card' 
                        className={`border-[1px] border-primary-yellow rounded-[20px] group-hover:cursor-pointer
                            ${(isCardOpen ? 'h-[22.5rem] lg:h-[27rem] lg:w-[24rem] w-64 bg-black/[0.75]' : !_isMobile ? 'lg:w-60 h-10 hover:w-72 bg-primary-yellow/[0.1]' : 'w-44 h-10 bg-primary-yellow/[0.1]')}`}
                        style={!_isMobile ? { transitionDuration: '500ms'} : {transitionDuration: '500ms'}}
                        onClick={() => (!isCardOpen ? setIsCardOpen(true) : setIsCardOpen(false))}> 
                        <div className={isCardOpen ? 'flex flex-col h-96 w-full pt-[2px]' : ''}>
                            <div className='flex flex-row items-center justify-between h-10'>
                                <div className='mx-3 text-sm font-bold tracking-widest uppercase text-primary-yellow font-IBM lg:text-base'>
                                    { chapterTitle }
                                </div>
                                <div className={`mx-2 transition-all scale-90 plus-yellow lg:scale-10 ${isCardOpen ? 'rotate-45': (!_isMobile ? 'group-hover:rotate-45 group-active:rotate-[135deg]' : '')}`}/> 
                            </div>
                            <div className={isCardOpen ? 'w-1/2 ml-2 border-t-2 border-dashed border-primary-yellow' : ''}/>
                            <div id={cardContentID} className='relative flex flex-row w-full h-full'>
                                <div className={isCardOpen ? 'absolute flex w-[96%] lg:w-5/6 ml-2 lg:ml-5 h-2/3 pt-1 lg:pt-2' : 'hidden'}>
                                    <span className='text-[13px] lg:text-base leading-tight lg:leading-tight font-IBM text-primary-yellow'>
                                        {/* --- Content from strapi - Break if ('_') --- */}
                                        {   chapterDescription &&
                                            chapterDescription.split('_').map((el: any, index: number) => {
                                                return (
                                                    <span key={index} className='flex flex-col'>
                                                        {el && el}
                                                        <br key={index + '-bb-1'} />
                                                        <div key={index + '-bb-2'} className={el.includes('Anno') ? 'hidden' : 'h-2'}/>
                                                    </span>
                                                )
                                            })
                                        }
                                    </span>
                                </div>
                                <div className={isCardOpen ? 'flex w-full h-full items-center justify-end absolute' : 'hidden'}>
                                    {/* - SVG QR-code - */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '64' : '78'} height={_isMobile ? '128' : '164'} viewBox="0 0 78.397 163.783">
                                        <g id="Raggruppa_159" data-name="Raggruppa 159" transform="translate(-1484.604 -576.463)">
                                            <path id="Tracciato_47" data-name="Tracciato 47" d="M55.264,81.4H46.554V72.686h8.711ZM46.554,50.909H37.843V72.686h8.711ZM81.4,42.2H72.686V59.62H81.4Zm-8.711-8.711H63.975V42.2h8.711ZM20.422,42.2H11.711v8.711h8.711Zm-8.711-8.711H3V42.2h8.711ZM42.2,11.711h8.711V3H42.2ZM9.533,9.533V22.6H22.6V9.533Zm19.6,19.6H3V3H29.132ZM9.533,61.8V74.864H22.6V61.8Zm19.6,19.6H3V55.264H29.132ZM61.8,9.533V22.6H74.864V9.533Zm19.6,19.6H55.264V3H81.4ZM72.686,72.686V59.62H55.264v8.711h8.711V81.4H81.4V72.686ZM63.975,42.2H46.554v8.711H63.975ZM46.554,33.488H20.422V42.2h8.711v8.711h8.711V42.2h8.711Zm4.355-4.355V20.422H42.2V11.711H33.488V29.132ZM19.333,12.8H12.8v6.533h6.533Zm0,52.265H12.8V71.6h6.533ZM71.6,12.8H65.064v6.533H71.6Z" transform="translate(1481.604 573.463)" fill="#fcf332" opacity="0.203"/>
                                            <path id="Tracciato_47-2" data-name="Tracciato 47" d="M51.01,76.515h-8.5v-8.5h8.5Zm-8.5-29.756h-8.5V68.013h8.5Zm34.007-8.5h-8.5v17h8.5Zm-8.5-8.5h-8.5v8.5h8.5ZM17,38.258H8.5v8.5H17Zm-8.5-8.5H0v8.5H8.5ZM38.258,8.5h8.5V0h-8.5ZM6.376,6.376V19.129H19.129V6.376ZM25.505,25.505H0V0H25.505ZM6.376,57.386V70.139H19.129V57.386ZM25.505,76.515H0V51.01H25.505ZM57.386,6.376V19.129H70.139V6.376ZM76.515,25.505H51.01V0H76.515Zm-8.5,42.508V55.261h-17v8.5h8.5V76.515h17v-8.5Zm-8.5-29.756h-17v8.5h17Zm-17-8.5H17v8.5h8.5v8.5h8.5v-8.5h8.5Zm4.251-4.251V17h-8.5V8.5h-8.5v17ZM15.941,9.564H9.564v6.376h6.376Zm0,51.01H9.564v6.376h6.376Zm51.01-51.01H60.574v6.376h6.376Z" transform="translate(1562.34 740.245) rotate(180)" fill="#fcf332" opacity="0.203"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={( _isMobile ? (isCardOpen ? 'flex trapezoid-b-yellow w-1/3 ml-5' : 'hidden trapezoid-b-yellow w-1/3 ml-5') 
                                            : (isCardOpen ? 'flex trapezoid-b-yellow w-1/2 ml-5' : 'group-hover:flex hidden trap-bounce trapezoid-b-yellow w-1/2 ml-5'))}/>
            </div>
        </div>
    )

}

export default DescriptionCards;